@import "ui-scss/colors.scss";
@import "ui-scss/mixins.scss";
@import "ui-scss/const.scss";

.Header {
  height: var(--header-height);
  padding: 0 16px;
  border-bottom: 1px solid $GRAY_200;

  &__MobileMenu {
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

    @include desktopStyles {
      display: none;
    }
  }

  &__RightPanel {
    display: flex;
    align-items: center;
  }

  &__VerticalDelimiter {
    width: 1px;
    height: 18px;
    background: $BLACK_500;
    opacity: 0.15;
    margin: 0 15px 0 2px;
  }

  &__FullScreenMenuWrapper {
    position: fixed;
    top: var(--header-height);
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    padding-bottom: var(--header-height);
    background-color: $WHITE;
    transform: translate(110%);
    transition: transform 0.2s ease-in-out;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1;

    &_opened {
      transform: translate(0);
    }
  }

  &__FullScreenMenu {
    margin: auto;
  }

  &__HorizontalDelimiter {
    width: 24px;
    height: 1px;
    background: $GRAY_300;
    margin: 32px auto;
    pointer-events: none;
  }

  &__NavBar {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include desktopStyles {
      flex-direction: row;
    }

    &_desktop {
      @include absoluteCenteredStyles;
    }
  }

  &__Link {
    text-decoration: none;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    color: $BLACK_500;
    margin-bottom: 24px;

    &:hover {
      color: $GRAY_500;
    }

    @include desktopStyles {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0;
      margin-right: 32px;
    }

    &_type {
      &_home {
        @include desktopStyles {
          display: none;
        }
      }

      &_pricing {
        font-weight: normal;
        position: relative;
        color: $GRAY_500;
        cursor: default;
        text-decoration: line-through;
        margin-bottom: 0;
      }
    }
  }

  &__TotalFree {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    position: absolute;
    left: 50%;
    bottom: 26px;
    color: $BLACK_500;
    white-space: nowrap;
    text-transform: capitalize;

    &:hover {
      color: $BLACK_500;
    }

    @include desktopStyles {
      bottom: 20px;
      font-size: 10px;
      line-height: 16px;
      left: 12px;
    }
  }

  &__Actions {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    @include desktopStyles {
      flex-direction: row;
    }
  }

  &__Button {
    margin: 0 0 24px;

    @include desktopStyles {
      margin: 0 8px;
    }
  }

  &__DesktopMenu {
    display: none;
    position: relative;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

    @include desktopStyles {
      display: flex;
    }
  }
}
