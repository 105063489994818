@import "ui-scss/colors.scss";

.Logo {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: $BLACK_500;
  cursor: pointer;

  &:hover {
    color: $GRAY_500;

    svg {
      circle {
        fill: $GRAY_500;
      }
    }
  }

  svg {
    margin-right: 8px;
  }
}
