@import "ui-scss/colors.scss";
@import "ui-scss/mixins.scss";

.UnauthorizedLayout {
  &__Main {
    min-height: calc(100vh - (var(--header-height) + var(--footer-height)));
    min-width: 0;
    overflow-x: hidden;
    display: flex;
  }

  &__Footer {
    @include flexStyles($justify: center, $direction: column, $align: center);
    border-top: 1px solid $GRAY_200;
    font-size: 12px;
    line-height: 18px;
    color: $GRAY_500;
    padding-top: 32px;
    padding-bottom: 40px;
  }

  &__Routes {
    @include flexStyles($gap: 16px);
    margin-top: 16px;
  }

  &__Link {
    color: inherit;
    text-decoration: none;

    &:hover {
      opacity: 0.4;
    }
  }
}
