html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  height: 100%;
}

body {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

:root {
  --header-height: 56px;
  --footer-height: 90px;
}
